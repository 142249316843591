.order-details-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.bodymovinanim {
  width: 90%;
  max-width: 50px;
  margin-bottom: 30px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-header h1 {
  margin: 0;
}

.order-status {
  display: flex;
  align-items: center;
}

.order-status .status {
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.order-status .paid {
  background-color: green;
}

.order-status .fulfilled {
  background-color: blue;
}

.order-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.order-items, .customer-info {
  flex: 1;
  margin-right: 20px;
}

.order-items h2, .customer-info h2 {
  margin-top: 0;
}

.order-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.order-item .item-details {
  flex: 1;
  margin-left: 20px;
}

.order-item .item-image {
  width: 100px;
  height: auto;
}

.order-summary {
  margin-top: 20px;
  text-align: right;
}

.order-summary p {
  margin: 5px 0;
}

.customer-details, .billing-details {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
}


/**/

.order-details {
  padding: 20px;
  overflow-x: hidden;
  margin-bottom: 2em;
  max-width: 1030px;
}

.order-detailed {
  padding: 20px;
  margin-top: -2.5em;
  overflow-x: hidden;
  max-width: 1030px;
  margin: 0 auto;
}

.mb-h4 {
  margin-bottom: 1.4em;
}

.order-header h4 {
  margin-bottom: 0;
}

.product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.order-items img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  margin-right: 15px;
}

.order-items h5 {
  font-size: 18px;
  margin-bottom: 5px;
}

.order-items p {
  margin-bottom: 5px;
}

.order-summary {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 20px;
}

.order-summary h5 {
  margin-bottom: 15px;
}

.order-summary p {
  margin-bottom: 5px;
}

.conInfo {
  margin-left: 20%;
}