.custom-chat-window {
    height: 85vh;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.admin-chat-bg {
  background-color: rgb(40, 43, 54) !important;
}

.ce-message-list-content {
  overflow-y: auto scroll !important;
  height: 100% !important;
}
