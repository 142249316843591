.login {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.login__logo {
  margin-top: 40px;
  margin-bottom: 35px;
  object-fit: contain;
  width: 150px;
  margin-right: auto;
  margin-left: auto;
}

.login_container {
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
  margin: 0 auto;
}

.login_container > h2 {
  font-weight: 500;
  font-size: medium;
  margin-bottom: 25px;
}

.login_container > form > h5 {
  margin-bottom: 5px;
  font-size: 17px;
}

.login_container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
}

.login_container > p {
  margin-top: 15px;
  font-size: 13px;
}

.Login_signInButton {
  background: #01d26a;
  border-radius: 2px;
  width: 100%;
  height: 35px;
  border: 1px solid;
  border-color: linear-gradient(to right, #01a853, #01d168, #00b05b) 1;
  color: white;
}

.Login_signInButton:hover {
  background-color: #028644;
  color: white;
  cursor: pointer;
}

.Login_signUpButton {
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: black;
  color: black;
  padding-left: 10px;
}

.error {
  color: red;
  font-size: 14px;
}

.form_login input[type="text"],
input[type="password"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .login {
    margin-top: 6%;
  }
}
