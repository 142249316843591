.add-products {
  width: 100%;
  max-width: 1050px;
  margin-top: 1.5em;
}

.add-product-information {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.sided-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.add-product-information label,
.sided-container label {
  display: block;
  margin-bottom: 10px;
}

.add-product-information input,
.sided-container input,
.sided-container select,
.add-product-information textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.floating-actions {
  margin-bottom: 2em;
  display: flex;
  gap: 10px;
}

.floating-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.floating-actions button:nth-child(1) {
  background-color: #ff4d4d;
  color: #fff;
  margin-right: 10rem;
}

.floating-actions button:nth-child(2) {
  background-color: #f0f0f0;
  color: #333;
}

.floating-actions button:nth-child(3) {
  background-color: #028644;
  color: #fff;
}

.floating-actions button:nth-child(3):hover {
  background-color: #01d26a;
  color: #fff;
}

.currency {
  position: relative;
  margin-top: -2.1em;
  padding-right: 15px;
  padding-left: 15px;
  color: #666;
}

.image-preview {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@media (max-width: 575px) {
  .add-products {
    gap: 20px;
  }

  .floating-actions button:nth-child(1) {
    margin-right: 4.5rem;
  }
}
