.not_found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin-top: -9.8em;
}

@media (orientation: landscape) {
    .not_found {
        margin-top: -5em;
    }
}