.bottom_footer {
  color: black;
  background-color: #f9fbf9;
  padding: 10px;
}

.copyright p {
  text-align: center;
}

.ciz {
  font-size: 16px;
}

.footer_main_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}

.btn_get_two {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #28a745;
  border-color: #28a745;
  color: #fff;
}

.btn_get_two:hover {
  background: transparent;
  color: #28a745;
}

.footer_main_top .f-title {
  margin-bottom: 10px;
  color: #131921;
}

.btn_get:hover {
  background: transparent;
  color: #28a745;
  border-color: #28a745;
}

a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

.footer_main_top .f_widget.about-widget .f_list li a:hover {
  color: #28a745;
}

.footer_main_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}

.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px;
}

.f_widget.about-widget .f_list li {
  margin-bottom: 15px;
}

.f_widget.about-widget .f_list {
  margin-bottom: 0px;
}

.f_600 {
  font-weight: 600;
}

.f_size_18 {
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4b505e;
}

@media screen and (min-width: 800px) {
  .copyright {
    text-align: left;
  }

  .ciz {
    text-align: right;
  }
}

@media (max-width: 768px) {
  .footer_bg {
    height: 150px;
    background-size: cover;
  }

  .footer_bg .footer_bg_bus {
    width: 220px;
    height: 70px;
    left: 20%;
  }

  .footer_bg .footer_bg_bike {
    width: 60px;
    height: 50px;
    left: 28%;
  }
}
