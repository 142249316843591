.below_navbar {
  background-color: #e7e6e6;
  min-height: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow-x: scroll;
  gap: 20px;
}

.below_navbar .active {
  font-weight: bold;
  color: #028644;
}

.below_navbar a {
  color: #131921;
  text-decoration: none;
  font-size: 16px;
}

.below_navbar a:hover {
  cursor: pointer;
}

.topnav-centered {
  text-align: center;
  align-items: center;
  padding: 10px;
  overflow-x: scroll;
}

.topnav-centered .active {
  font-weight: bold;
  color: #028644;
}

@media (max-width: 768px) {
  .below_navbar a {
    font-size: 13px;
  }

  .below_navbar {
    gap: 10px;
  }
}
