.all__products {
  max-width: 1300px;
}

/* .table {
  width: 100%;
  border-collapse: collapse;
} */

.thead-light {
  background-color: #f1f1f1;
}

.product-image {
  width: 50px;
  height: auto;
}

/* .table th,
.table td {
  padding: 10px;
  text-align: left;
}

.table th {
  background-color: #f1f1f1;
  font-weight: bold;
}

.table td {
  border-bottom: 1px solid #ddd;
}
 */
/* .table td:last-child {
  display: flex;
  align-items: center;
} */

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox input[type="checkbox"] {
  margin: 0;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 3em;
}

.pagination-controls button {
  margin: 0 10px;
  color: #028644;
}

.load-more {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.search_func {
  margin: 0 auto;
}

.all__search {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 20px;
  position: relative;
  max-width: 600px;
  width: 100%;
  margin-bottom: 0.4em;
}

/* .icon__cell {
  display: flex !important;
  margin-top: 0.8em;
} */