.dboard {
  width: 93%;
  max-width: 1030px;
  margin: 0 auto;
}

.dash__card {
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 7px;
}

.short__card {
  padding-bottom: 5px;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.short__card_v {
  padding: 20px;
  width: 100%;
}

.ts_span {
  font-size: 15px;
  font-weight: 500;
}

.perc_span {
  font-size: 11px;
  color: blue;
  font-weight: 500;
}

.short__card_ts {
  padding-bottom: 20px;
}

.ts_price {
  font-size: 20px;
}

.short__card_ts {
  background-color: rgb(255, 226, 229);
}

.short__card_to {
  background-color: rgb(255, 244, 222);
}

.short__card_ps {
  background-color: rgb(220, 252, 231);
}

.short__card_nc {
  background-color: rgb(243, 232, 255);
}

.ad_ts {
  background-color: rgb(250, 90, 125);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.ad_to {
  background-color: rgb(255, 148, 122);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.ad_ps {
  background-color: rgb(60, 216, 86);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.ad_nc {
  background-color: rgb(191, 131, 255);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.table__list {
  padding: 15px;
  max-width: 1050px;
}
